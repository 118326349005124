<app-rp-loader></app-rp-loader>
<!-- <div class="container-fluid">
  <div bsModal tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered"> -->
<div class="modal-content custom-modal">
  <div class="modal-header bg-primary text-white" >
    <h5 class="modal-title">Profile</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closePopUp()"></button>
  </div>
  <div class="modal-body">
    <form class="needs-validation" [formGroup]="profileForm" (ngSubmit)="onSubmit()" (keyup.enter)="onSubmit()"
      novalidate>
      <div class="row">
        <div class="col-sm-12 col-md-4 col-lg-4 text-center">

          <div class="profile-container">
            <label class="hoverable" for="fileInput">
              <img [src]="url ? url : '../../../assets/images/users/user-dummy-img.jpg'" />
              <div class="background"></div>
            </label>
            <br />
            <input id="profileImg" type="file" accept=".jpg, .jpeg, .png" class="select-profile-picture"
              (change)="onSelectFile($event)" />
            <span class="message text-primary text-decoration-underline font-size-16">Change picture</span>
          </div>


        </div>
        <div class="col-sm-12 col-md-8 col-lg-8">

          <!-- <form  class="needs-validation"   [formGroup]="profileForm"  (ngSubmit)="onSubmit()" (keyup.enter)="onSubmit()" novalidate> -->
          <div class="row">

            <div class="mb-3 col-lg-6">
              <label>First Name <span class="required">*</span></label>
              <input type="text" formControlName="firstName" id="firstName" name="firstName"
                placeholder="Enter First Name" class="form-control" />
              @if(submitted && f['firstName'].errors){
              <div class="invalid-feedback">
                @if(f['firstName'].errors['required']){
                <div>{{prifileValidations.firstName.firstName_required_Validations}}</div>}

                @if(f['firstName'].errors['minlength']){
                <div>{{prifileValidations.firstName.min_required_Validations}}</div>
                }
                @if(f['firstName'].errors['maxlength']){
                <div>{{prifileValidations.firstName.min_required_Validations}}</div>
                }
                @if(f['firstName'].errors['pattern']){
                <div>{{prifileValidations.firstName.allow_spaces}}</div>}


              </div>}
            </div>

            <div class="mb-3 col-lg-6">
              <label>Last Name <span class="required">*</span></label>
              <input type="text" formControlName="lastName" id="lastName" placeholder="Enter Last Name"
                class="form-control" />
              @if(submitted && f['lastName'].errors){
              <div class="invalid-feedback">
                @if(f['lastName'].errors['required']){
                <div>{{prifileValidations.lastName.lastName_required_Validations}}</div>}
                @if(f['lastName'].errors['minlength']){
                <div>{{prifileValidations.lastName.min_required_Validations}}</div>
                }
                @if(f['lastName'].errors['maxlength']){
                <div>{{prifileValidations.lastName.min_required_Validations}}</div>
                }
                @if(f['lastName'].errors['pattern']){
                <div>{{prifileValidations.lastName.allow_spaces}}</div>}

              </div>}
            </div>

            <div class="mb-3 col-lg-6">
              <label>Email Address <span class="required">*</span></label>
              <input type="text" formControlName="emailAddress" id="emailAddress" placeholder="Enter Email Address"
                class="form-control" />
              @if(submitted && f['emailAddress'].errors){
              <div class="invalid-feedback">
                @if(f['emailAddress'].errors['required']){
                <div>{{prifileValidations.emmail_validation.email_required_validations}}</div>}
              </div>}
            </div>





            <div class="mb-3 col-lg-6">
              <div class="mb-3">
                <label for="b">Mobile Number - Primary<span class="required">*</span></label>
                
                <!-- Country Code Dropdown using ng-select -->
                <div class="input-group">
                  
                  <!-- ng-select with smaller width -->
                  <ng-select [items]="configData?.data?.countryCodes"
                             bindLabel="name"
                             bindValue="id"
                             formControlName="countryCode"
                             placeholder="Select Country"
                             class="ng-select-custom-size">                      
                    <!-- Template for displaying the selected item -->
                    <ng-template ng-label-tmp let-item="item">
                      <span>{{ item.name }} ({{ item.code }})</span>
                    </ng-template>
                    
                    <!-- Template for displaying the options in the dropdown -->
                    <ng-template ng-option-tmp let-item="item">
                      <span>{{ item.name }} ({{ item.code }})</span>
                    </ng-template>
                  </ng-select>
            
                  <!-- Mobile Number Input with larger width -->
                  <input type="text" class="form-control phone-number-input" id="mobileNumber" 
                         formControlName="mobileNumber" placeholder="Enter Mobile Number">
                </div>
            
                
                <!-- Validation Feedback -->
                 @if(submitted && f['mobileNumber'].errors){
              <div class="invalid-feedback">
                @if(f['mobileNumber'].errors['required']){
                <div>{{prifileValidations.mobile_validation.mobile_required_validations}}</div>}
              </div>}
              </div>
            </div>
            

            <!-- <div class="mb-3 col-lg-6">
              <label>Mobile Number <span class="required">*</span></label>
              <input type="text" formControlName="mobileNumber" id="mobileNumber" placeholder="Enter Mobile Number"
                class="form-control" />
              @if(submitted && f['mobileNumber'].errors){
              <div class="invalid-feedback">
                @if(f['mobileNumber'].errors['required']){
                <div>{{prifileValidations.mobile_validation.mobile_required_validations}}</div>}
              </div>}
            </div> -->



            <div class="mb-3 col-lg-6">
              <label>Secondary Email </label>
              <input type="text" formControlName="secondaryEmail" id="secondaryEmail"
                placeholder="Enter Secondary Email" class="form-control" />
              @if(submitted && f['secondaryEmail'].errors){
              <div class="invalid-feedback">
                @if(f['secondaryEmail'].errors['pattern']){
                <div>{{prifileValidations.emmail_validation.email_pattern}}</div>}
              </div>}
            </div>


            <div class="mb-3 col-lg-6">
              <!-- <div class="mb-3"> -->
                <label for="b">Mobile Number - Secondary</label>
                
                <!-- Country Code Dropdown using ng-select -->
                <div class="input-group">
                  
                  <!-- ng-select with smaller width -->
                  <ng-select [items]="configData?.data?.countryCodes"
                             bindLabel="name"
                             bindValue="id"
                             formControlName="secondaryCountryCode"
                             placeholder="Select Country"
                             class="ng-select-custom-size"
                            >                      
                    <!-- Template for displaying the selected item -->
                    <ng-template ng-label-tmp let-item="item">
                      <span title="{{item.name}}" kendoTooltip>{{ item.name }} ({{ item.code }})</span>
                    </ng-template>
                    
                    <!-- Template for displaying the options in the dropdown -->
                    <ng-template ng-option-tmp let-item="item">
                      <span  title="{{item.name}}" kendoTooltip>{{ item.name }} ({{ item.code }})</span>
                    </ng-template>
                  </ng-select>
            
                  <!-- Mobile Number Input with larger width -->
                  <input type="text" class="form-control phone-number-input" id="mobile_Primary_Number" 
                         formControlName="secondaryPhoneNumber" placeholder="Enter Phone Number">
                </div>
            
                <div *ngIf="f['secondaryPhoneNumber'].value!='' && f['secondaryCountryCode'].value==null" class="invalid-feedback">
                  <div>
                    {{ prifileValidations.secondaryCountryCode.secondaryCountryCode_required_Validations }}
                  </div>
                </div>
                <!-- Validation Feedback -->
                @if(submitted && f['secondaryPhoneNumber'].errors){
                  <div class="invalid-feedback">
                    <!--  @if(f['secondaryPhoneNumber'].errors['required']){
                    <div>{{prifileValidations.secondaryPhoneNumber.lastName_required_Validations}}</div>} -->
                    @if(f['secondaryPhoneNumber'].errors['minlength']){
                    <div>{{prifileValidations.secondaryPhoneNumber.min_required_Validations}}</div>
                    }
                    @if(f['secondaryPhoneNumber'].errors['maxlength']){
                    <div>{{prifileValidations.secondaryPhoneNumber.min_required_Validations}}</div>
                    }
                    @if(f['secondaryPhoneNumber'].errors['pattern']){
                    <div>{{prifileValidations.secondaryPhoneNumber.allow_spaces}}</div>}
    
                  </div>}
              </div>
            </div>




            <!-- <div class="mb-3 col-lg-6">
              <label> Secondary Phone Number</label>
              <input type="text" formControlName="secondaryPhoneNumber" id="secondaryPhoneNumber"
                placeholder="Enter Secondary Phone Number" class="form-control" />
              @if(submitted && f['secondaryPhoneNumber'].errors){
              <div class="invalid-feedback">
                @if(f['secondaryPhoneNumber'].errors['minlength']){
                <div>{{prifileValidations.secondaryPhoneNumber.min_required_Validations}}</div>
                }
                @if(f['secondaryPhoneNumber'].errors['maxlength']){
                <div>{{prifileValidations.secondaryPhoneNumber.min_required_Validations}}</div>
                }
                @if(f['secondaryPhoneNumber'].errors['pattern']){
                <div>{{prifileValidations.secondaryPhoneNumber.allow_spaces}}</div>}

              </div>}
            </div>


          </div> -->

          <!-- </form> -->

        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" >Close</button> -->
    <button type="button" class="btn btn-primary waves-effect waves-light" (click)="onSubmit()">Save</button>
  </div>
</div><!-- /.modal -->
<!-- </div>
  </div>
</div> -->