import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RpUsersComponent } from './rp-users/rp-users.component';
import { RpCreateUserComponent } from './rp-create-user/rp-create-user.component';

const routes: Routes = [
  { path: 'users', component: RpUsersComponent },
  { path: 'users/createUser', component: RpCreateUserComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UsersRoutingModule { }
