import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserlistState } from './userlist.reducer';

export const selectDataState = createFeatureSelector<UserlistState>('userList');

export const getUsersResponse = createSelector(
  selectDataState,
  (state: UserlistState) => state.getUserResponse
);

export const createUserResponse = createSelector(
  selectDataState,
  (state: UserlistState) => state.createUserResponse
);

export const getAllUserResponse = createSelector(
  selectDataState,
  (state: UserlistState) => state.getAllUserResponse
);

export const updateUserResponse = createSelector(
  selectDataState,
  (state: UserlistState) => state.updateUserResponse
);


export const getuserStatusResponse = createSelector(
  selectDataState,
  (state: UserlistState) => state.getuserStatusResponse
);



//getuserrStatusResponse