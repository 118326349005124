import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { UsersRoutingModule } from './users-routing.module';
import { RpUsersComponent } from './rp-users/rp-users.component';
import { RpCreateUserComponent } from './rp-create-user/rp-create-user.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {
  GridModule,
  PDFModule,
  ExcelModule,
} from '@progress/kendo-angular-grid';
import { UiSwitchModule } from 'ngx-ui-switch';

//import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { RpUserProfileComponent } from './rp-user-profile/rp-user-profile.component';
import { SharedModule } from '../../shared/shared.module';
import { RpChangepasswordComponent } from './rp-changepassword/rp-changepassword.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LabelModule } from "@progress/kendo-angular-label";
import { ComponentsModule } from '../../shared/components/components.module';

@NgModule({
  declarations: [
    RpUsersComponent,
    RpCreateUserComponent,
    RpUserProfileComponent,
    RpChangepasswordComponent,



  ],
  imports: [
    CommonModule,
    NgSelectModule,
    UsersRoutingModule,
    BsDatepickerModule,
    GridModule,
    PDFModule,
    ExcelModule,
    TextBoxModule, UiSwitchModule,
    ModalModule, ComponentsModule,
    SharedModule, LabelModule, DropDownsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class UsersModule { }
